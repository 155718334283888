/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      contacts {
        id
        agentId
        channel
        initiationMethod
        status {
          name
          startTimestamp
          type
        }
      }
      firstName
      lastName
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      status {
        name
        startTimestamp
        type
      }
      username
      createdAt
      updatedAt
    }
  }
`;

export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contacts {
          id
          agentId
          channel
          initiationMethod
          status {
            name
            startTimestamp
            type
          }
        }
        firstName
        lastName
        routingProfile {
          id
          createdAt
          updatedAt
        }
        routingProfileId
        status {
          name
          startTimestamp
          type
        }
        username
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getAggregatedQueue = /* GraphQL */ `
  query GetAggregatedQueue($id: ID!) {
    getAggregatedQueue(id: $id) {
      id
      currentMetricData {
        agentsAfterContactWork
        agentsAvailable
        agentsError
        agentsNonProductive
        agentsOnCall
        agentsOnContact
        agentsOnline
        agentsStaffed
        contactsInQueue
        contactsScheduled
        oldestContactAge
        slotsActive
        slotsAvailable
      }
      metricData {
        abandonTime
        afterContactWorkTime
        apiContactsHandled
        callbackContactsHandled
        contactsAbandoned
        contactsAgentHungUpFirst
        contactsConsulted
        contactsHandled
        contactsHandledIncoming
        contactsHandledOutbound
        contactsHoldAbandons
        contactsMissed
        contactsQueued
        contactsTransferredIn
        contactsTransferredInFromQueue
        contactsTransferredOut
        contactsTransferredOutFromQueue
        handleTime
        holdTime
        interactionAndHoldTime
        interactionTime
        occupancy
        queueAnswerTime
        queuedTime
        serviceLevel15
        serviceLevel20
        serviceLevel25
        serviceLevel30
        serviceLevel45
        serviceLevel60
        serviceLevel90
        serviceLevel120
        serviceLevel180
        serviceLevel240
        serviceLevel300
        serviceLevel600
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAggregatedQueues = /* GraphQL */ `
  query ListAggregatedQueues(
    $filter: ModelAggregatedQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAggregatedQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentMetricData {
          agentsAfterContactWork
          agentsAvailable
          agentsError
          agentsNonProductive
          agentsOnCall
          agentsOnContact
          agentsOnline
          agentsStaffed
          contactsInQueue
          contactsScheduled
          oldestContactAge
          slotsActive
          slotsAvailable
        }
        metricData {
          abandonTime
          afterContactWorkTime
          apiContactsHandled
          callbackContactsHandled
          contactsAbandoned
          contactsAgentHungUpFirst
          contactsConsulted
          contactsHandled
          contactsHandledIncoming
          contactsHandledOutbound
          contactsHoldAbandons
          contactsMissed
          contactsQueued
          contactsTransferredIn
          contactsTransferredInFromQueue
          contactsTransferredOut
          contactsTransferredOutFromQueue
          handleTime
          holdTime
          interactionAndHoldTime
          interactionTime
          occupancy
          queueAnswerTime
          queuedTime
          serviceLevel15
          serviceLevel20
          serviceLevel25
          serviceLevel30
          serviceLevel45
          serviceLevel60
          serviceLevel90
          serviceLevel120
          serviceLevel180
          serviceLevel240
          serviceLevel300
          serviceLevel600
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      name
      pages
      createdAt
      updatedAt
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pages
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoutingProfile = /* GraphQL */ `
  query GetRoutingProfile($id: ID!) {
    getRoutingProfile(id: $id) {
      id
      createdAt
      updatedAt
      inboundQueues {
        items {
          id
          routingProfileId
          queueId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRoutingProfiles = /* GraphQL */ `
  query ListRoutingProfiles(
    $filter: ModelRoutingProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoutingProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      config {
        id
        name
        pages
        createdAt
        updatedAt
      }
      configId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        config {
          id
          name
          pages
          createdAt
          updatedAt
        }
        configId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getQueueRoutingProfile = /* GraphQL */ `
  query GetQueueRoutingProfile($id: ID!) {
    getQueueRoutingProfile(id: $id) {
      id
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      queueId
      createdAt
      updatedAt
    }
  }
`;

export const listQueueRoutingProfiles = /* GraphQL */ `
  query ListQueueRoutingProfiles(
    $filter: ModelQueueRoutingProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueueRoutingProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        routingProfile {
          id
          createdAt
          updatedAt
        }
        routingProfileId
        queueId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
