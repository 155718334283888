import { Grid, Paper, Typography } from "@mui/material";
import { get, sortBy } from "lodash";
import React from "react";
import { AggregatedQueue, Threshold } from "../../types";
import { formatDuration } from "../../util";

const units = {
  currentMetricData: {
    agentsAfterContactWork: "COUNT",
    agentsAvailable: "COUNT",
    agentsError: "COUNT",
    agentsNonProductive: "COUNT",
    agentsOnCall: "COUNT",
    agentsOnContact: "COUNT",
    agentsOnline: "COUNT",
    agentsStaffed: "COUNT",
    contactsInQueue: "COUNT",
    contactsScheduled: "COUNT",
    oldestContactAge: "SECONDS",
    slotsActive: "COUNT",
    slotsAvailable: "COUNT",
  },
  metricData: {
    abandonTime: "SECONDS",
    afterContactWorkTime: "SECONDS",
    apiContactsHandled: "COUNT",
    callbackContactsHandled: "COUNT",
    contactsAbandoned: "COUNT",
    contactsAgentHungUpFirst: "COUNT",
    contactsConsulted: "COUNT",
    contactsHandled: "COUNT",
    contactsHandledIncoming: "COUNT",
    contactsHandledOutbound: "COUNT",
    contactsHoldAbandons: "COUNT",
    contactsMissed: "COUNT",
    contactsQueued: "COUNT",
    contactsTransferredIn: "COUNT",
    contactsTransferredInFromQueue: "COUNT",
    contactsTransferredOut: "COUNT",
    contactsTransferredOutFromQueue: "COUNT",
    handleTime: "SECONDS",
    holdTime: "SECONDS",
    interactionAndHoldTime: "SECONDS",
    interactionTime: "SECONDS",
    occupancy: "PERCENT",
    queueAnswerTime: "SECONDS",
    queuedTime: "SECONDS",
    serviceLevel15: "PERCENT",
    serviceLevel20: "PERCENT",
    serviceLevel25: "PERCENT",
    serviceLevel30: "PERCENT",
    serviceLevel45: "PERCENT",
    serviceLevel60: "PERCENT",
    serviceLevel90: "PERCENT",
    serviceLevel120: "PERCENT",
    serviceLevel180: "PERCENT",
    serviceLevel240: "PERCENT",
    serviceLevel300: "PERCENT",
    serviceLevel600: "PERCENT",
  },
};

const thresholdColor = (severity?: string) => {
  switch (severity) {
    case "high":
      return {
        backgroundColor: "error.main",
        color: "error.contrastText",
      };
    case "low":
      return {
        backgroundColor: "success.main",
        color: "success.contrastText",
      };
    case "medium":
      return {
        backgroundColor: "warning.main",
        color: "warning.contrastText",
      };
    default:
      return {};
  }
};

const thresholdSeverity = (thresholds: Threshold[], value: number) => {
  const sortedThresholds = sortBy(thresholds, "value");

  for (let i = sortedThresholds.length - 1; i >= 0; i--) {
    const threshold = sortedThresholds[i];
    if (value > threshold.value) return threshold.severity;
  }
};

type MetricProps = {
  aggregatedQueue: AggregatedQueue;
  label: string;
  name: string;
  thresholds: Threshold[];
};

export default function Metric({
  aggregatedQueue,
  label,
  name,
  thresholds,
}: MetricProps) {
  const value = get(aggregatedQueue, name) || 0;

  let displayValue;

  switch (get(units, name)) {
    case "PERCENT":
      displayValue = `${value}%`;
      break;
    case "SECONDS":
      displayValue = formatDuration(value);
      break;
    case "COUNT":
    default:
      displayValue = value;
      break;
  }

  return (
    <Grid item xs>
      <Paper
        sx={{
          ...thresholdColor(thresholdSeverity(thresholds, value)),
          height: "100%",
          textAlign: "center",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          spacing={1}
          sx={{
            height: "100%",
          }}
          wrap="nowrap"
        >
          <Grid item xs="auto">
            <Typography variant="h2">{displayValue}</Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="h5">{label}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
