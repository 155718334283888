import {
  Call as CallIcon,
  CallMissed as CallMissedIcon,
  Chat as ChatIcon,
  Circle as CircleIcon,
  Error as ErrorIcon,
  PhoneCallback as PhoneCallbackIcon,
  PhoneForwarded as PhoneForwardedIcon,
} from "@mui/icons-material";
import { Icon as MuiIcon } from "@mui/material";
import React from "react";

const color = (channel: string, statusType: string) => {
  if (channel) {
    switch (statusType) {
      case "CONNECTED":
        return "success.main";
      case "CONNECTED_ONHOLD":
      case "CONNECTING":
      case "INCOMING": // The system calls an agent when a callback is due.
      case "PENDING":
        return "warning.main";
      case "ENDED":
        return "info.main";
      case "ERROR":
      case "MISSED":
      case "REJECTED":
        return "error.main";
      default:
        return "red";
    }
  } else {
    switch (statusType) {
      case "CUSTOM":
        return "info.main";
      case "OFFLINE":
        return "text.disabled";
      case "ROUTABLE":
        return "success.main";
      default:
        return "red";
    }
  }
};

type IconProps = {
  channel: string;
  initiationMethod: string;
  statusType: string;
};

function Icon({ channel, initiationMethod, statusType }: IconProps) {
  if (channel) {
    switch (statusType) {
      case "ERROR":
        return <ErrorIcon />;
      case "MISSED":
        return <CallMissedIcon />;
      default:
        switch (channel) {
          case "CHAT":
            return <ChatIcon />;
          case "VOICE":
            switch (initiationMethod) {
              case "API":
              case "OUTBOUND":
                return <PhoneForwardedIcon />;
              case "CALLBACK":
              case "INBOUND":
                return <PhoneCallbackIcon />;
              case "DISCONNECT":
              case "QUEUE_TRANSFER":
              case "TRANSFER":
              default:
                return <CallIcon />;
            }
          default:
            return <></>;
        }
    }
  } else {
    switch (statusType) {
      case "CUSTOM":
      case "OFFLINE":
      case "ROUTABLE":
        return <CircleIcon />;
      default:
        return <></>;
    }
  }
}

type StatusTypeIconProps = {
  channel: string;
  initiationMethod: string;
  statusType: string;
};

export default function StatusTypeIcon({
  channel,
  initiationMethod,
  statusType,
}: StatusTypeIconProps) {
  if (
    statusType.toUpperCase() === statusType &&
    ![
      "CONNECTED",
      "CONNECTED_ONHOLD",
      "CONNECTING",
      "CUSTOM",
      "ENDED",
      "ERROR",
      "INCOMING",
      "MISSED",
      "OFFLINE",
      "PENDING",
      "REJECTED",
      "ROUTABLE",
    ].includes(statusType)
  ) {
    console.log(statusType);
  }

  return (
    <MuiIcon sx={{ color: color(channel, statusType), margin: "0 5px 0 0" }}>
      <Icon
        channel={channel}
        initiationMethod={initiationMethod}
        statusType={statusType}
      />
    </MuiIcon>
  );
}
