const numberOfSecondsInAMinute = 60;
const numberOfSecondsInAnHour = numberOfSecondsInAMinute * 60;
const numberOfSecondsInADay = numberOfSecondsInAnHour * 24;

export default function formatDuration(durationSeconds: number) {
  let secondsRemaining = durationSeconds;

  if (secondsRemaining < 0) {
    return;
  }

  const days = Math.floor(secondsRemaining / numberOfSecondsInADay);

  secondsRemaining -= days * numberOfSecondsInADay;

  const hours = Math.floor(secondsRemaining / numberOfSecondsInAnHour);

  secondsRemaining -= hours * numberOfSecondsInAnHour;

  const minutes = Math.floor(secondsRemaining / numberOfSecondsInAMinute);

  secondsRemaining -= minutes * numberOfSecondsInAMinute;

  const seconds = secondsRemaining;

  if (days > 0) return `${days}d ${hours}h ${minutes}m ${seconds}s`;

  if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`;

  if (minutes > 0) return `${minutes}m ${seconds}s`;

  return `${seconds}s`;
}
