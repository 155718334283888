import { LinearProgress as MuiLinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

type LinearProgressProps = {
  delay?: number;
  onNextPage: () => void;
  play: boolean;
};

export default function LinearProgress({
  delay,
  onNextPage: handleNextPage,
  play,
}: LinearProgressProps) {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (delay) {
      const interval = setInterval(
        () => setProgress((progress) => (progress === 100 ? 0 : progress + 2)),
        delay / 50
      );

      if (!play) clearInterval(interval);

      return () => clearInterval(interval);
    }
    return;
  }, [delay, play, setProgress]);

  useEffect(() => {
    if (progress === 100) handleNextPage();
  }, [handleNextPage, progress]);

  return (
    <MuiLinearProgress color="primary" variant="determinate" value={progress} />
  );
}
