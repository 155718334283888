import {
  gql,
  OperationVariables,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { MetricsColumns } from ".";
import { listAggregatedQueues as LIST_AGGREGATED_QUEUES } from "../../graphql/queries";
import { onUpdateAggregatedQueue as ON_UPDATE_AGGREGATED_QUEUE } from "../../graphql/subscriptions";
import { AggregatedQueue, AppSyncQueryResult } from "../../types";

type AggregatedQueuesProps = {
  aggregatedQueues: AggregatedQueue[];
};

export default function AggregatedQueues(props: AggregatedQueuesProps) {
  const [listAggregatedQueues] = useLazyQuery(gql(LIST_AGGREGATED_QUEUES));
  const [aggregatedQueues, setAggregatedQueues] = useState<AggregatedQueue[]>(
    []
  );

  useEffect(() => {
    if (props.aggregatedQueues)
      (async () => {
        const filter = {
          or: props.aggregatedQueues?.map((aggregatedQueue) => ({
            id: {
              eq: aggregatedQueue.id,
            },
          })),
        };

        let nextToken,
          aggregatedQueues: AggregatedQueue[] = [];

        do {
          const result: AppSyncQueryResult<
            {
              listAggregatedQueues: {
                items: AggregatedQueue[];
                nextToken?: string;
              };
            },
            OperationVariables
          > = await listAggregatedQueues({
            variables: {
              filter,
              nextToken,
            },
          });

          if (result.errors) console.error(result.errors);

          aggregatedQueues = [
            ...aggregatedQueues,
            ...(result.data?.listAggregatedQueues.items ?? []),
          ];
          nextToken = result.data?.listAggregatedQueues.nextToken;
        } while (nextToken);

        setAggregatedQueues(aggregatedQueues);
      })();
  }, [props.aggregatedQueues, listAggregatedQueues]);

  useSubscription<{
    onUpdateAggregatedQueue: AggregatedQueue;
  }>(gql(ON_UPDATE_AGGREGATED_QUEUE), {
    onSubscriptionData: (options) => {
      if (options.subscriptionData.error)
        console.error(options.subscriptionData.error);
      else if (
        props.aggregatedQueues?.some(
          (aggregatedQueue) =>
            aggregatedQueue.id ===
            options.subscriptionData.data?.onUpdateAggregatedQueue.id
        )
      ) {
        setAggregatedQueues((aggregatedQueues) =>
          aggregatedQueues.map((aggregatedQueue) =>
            aggregatedQueue.id ===
            options.subscriptionData.data?.onUpdateAggregatedQueue.id
              ? options.subscriptionData.data.onUpdateAggregatedQueue
              : aggregatedQueue
          )
        );
      }
    },
    shouldResubscribe: true,
  });

  const combinedAggregatedQueues = useMemo<AggregatedQueue[]>(() => {
    return (
      props.aggregatedQueues?.map((propsAggregatedQueue) => ({
        ...aggregatedQueues.find(
          (aggregatedQueue) => aggregatedQueue.id === propsAggregatedQueue.id
        ),
        ...propsAggregatedQueue,
      })) ?? []
    );
  }, [aggregatedQueues, props.aggregatedQueues]);

  return (
    <>
      {combinedAggregatedQueues.map((aggregatedQueue, index) => {
        return (
          <Grid
            container
            direction="column"
            item
            justifyContent="space-between"
            key={index}
            spacing={1}
            sx={{
              width: `${100 / props.aggregatedQueues?.length || 0}%`,
            }}
            xs
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
              }}
              xs="auto"
            >
              <Typography noWrap variant="h6">
                {aggregatedQueue.name}
              </Typography>
            </Grid>
            <Grid container item spacing={1} xs>
              <MetricsColumns aggregatedQueue={aggregatedQueue} />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
