import { gql, useMutation } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { LinearProgress } from ".";
import { updateUser as UPDATE_USER } from "../graphql/mutations";
import { Page, User } from "../types";
import { Agents } from "./agents";
import { AggregatedQueues } from "./aggregated-queues";
import { AppBar } from "./app-bar";

export default function View() {
  const { user } = useAuthenticator();
  const [updateUser] = useMutation<{ updateUser: User }>(gql(UPDATE_USER));
  const [delay, setDelay] = useState<number>();
  const [page, setPage] = useState<number>(0);
  const [pages, setPages] = useState<Page[]>([]);
  const [play, setPlay] = useState<boolean>(true);

  useEffect(() => {
    if (pages.length) setDelay(pages[page]?.delay);
  }, [page, pages]);

  const handleChangeConfig = useCallback(
    (config) => {
      (async () => {
        if (config.id && user)
          updateUser({
            variables: {
              input: {
                id: user.username,
                configId: config.id,
              },
            },
          });
      })();

      setPage(0);
      setPages(JSON.parse(config.pages || "[]"));
    },
    [updateUser, user]
  );

  const handleNextPage = useCallback(() => {
    if (pages.length)
      setPage((page) => {
        const numberOfPages = pages.length - 1;
        if (page === numberOfPages) return 0;
        else return page + 1;
      });
  }, [pages]);

  const handlePauseResume = useCallback(() => setPlay((play) => !play), []);

  const handlePreviousPage = useCallback(() => {
    if (pages.length)
      setPage((page) => {
        const numberOfPages = pages.length - 1;
        if (page === 0) return numberOfPages;
        else return page - 1;
      });
  }, [pages]);

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: "100vh",
        overflow: "hidden",
        width: "100vw",
      }}
      wrap="nowrap"
    >
      <Grid item xs="auto">
        <AppBar
          onChangeConfig={handleChangeConfig}
          onNextPage={handleNextPage}
          onPauseResume={handlePauseResume}
          onPreviousPage={handlePreviousPage}
          play={play}
        />
      </Grid>
      <Grid item xs="auto">
        <LinearProgress delay={delay} onNextPage={handleNextPage} play={play} />
      </Grid>
      <Grid container item justifyContent="space-between" xs>
        <Grid container item spacing={1} xs>
          <AggregatedQueues aggregatedQueues={pages[page]?.aggregatedQueues} />
        </Grid>
        <Grid item xs="auto">
          <Agents
            aggregatedQueues={pages[page]?.aggregatedQueues}
            maximumStatusDuration={pages[page]?.agents?.maximumStatusDuration}
            page={page}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
