// import { Menu, MenuItem } from "@mui/material";
import {
  gql,
  OperationVariables,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import { DataGrid, GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import { differenceInMilliseconds, differenceInSeconds } from "date-fns";
import { isEqual, uniq } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { StatusTypeIcon } from ".";
import {
  listAgents as LIST_AGENTS,
  listQueueRoutingProfiles as LIST_QUEUE_ROUTING_PROFILES,
} from "../../graphql/queries";
import {
  onCreateAgent as ON_CREATE_AGENT,
  onDeleteAgent as ON_DELETE_AGENT,
  onUpdateAgent as ON_UPDATE_AGENT,
} from "../../graphql/subscriptions";
import { usePrevious } from "../../hooks";
import {
  Agent,
  AggregatedQueue,
  AppSyncQueryResult,
  QueueRoutingProfile,
} from "../../types";
import { formatDuration } from "../../util";

const getStatusTypeValue = (statusName: string, statusType: string) => {
  switch (statusType) {
    case "ACW":
      return "ACW";
    case "CONNECTED":
      return "Connected";
    case "CONNECTED_ONHOLD":
      return "On hold";
    case "CONNECTING":
    case "INCOMING":
    case "PENDING":
      return "Incoming";
    case "CUSTOM":
      return statusName || "NPT";
    case "ENDED":
      return "Ended";
    case "ERROR":
      return "Error";
    case "MISSED":
      return "Missed";
    case "OFFLINE":
      return "Offline";
    case "REJECTED":
      return "Rejected";
    case "ROUTABLE":
      return "Available";
    default:
      return statusName || statusType;
  }
};

// function ColumnMenu(props) {
//   console.log("Props:", props);

//   const sortingOrder = uniq(
//     props.options.sortingOrder.filter((sort) =>
//       [null, "asc", "desc"].includes(sort)
//     )
//   );

//   return (
//     <Menu
//       anchorEl={props.api.current.columnHeadersElementRef.current}
//       id={props.id}
//       keepMounted
//       onClose={props.hideMenu}
//       open={props.open}
//     >
//       {sortingOrder.map((sort, index) => {
//         switch (sort) {
//           case "asc":
//             return <MenuItem key={index}>Sort ASC</MenuItem>;
//           case "desc":
//             return <MenuItem key={index}>Sort DESC</MenuItem>;
//           case null:
//           default:
//             return <MenuItem key={index}>Unsort</MenuItem>;
//         }
//       })}
//     </Menu>
//   );
// }

type AgentsProps = {
  aggregatedQueues: AggregatedQueue[];
  maximumStatusDuration: number;
  page: number;
};

export default function Agents({
  aggregatedQueues,
  maximumStatusDuration,
  page,
}: AgentsProps) {
  const [listAgents] = useLazyQuery(gql(LIST_AGENTS));
  const [listQueueRoutingProfiles] = useLazyQuery(
    gql(LIST_QUEUE_ROUTING_PROFILES)
  );
  const previousPage = usePrevious<number>(page);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [queueIds, setQueueIds] = useState<string[]>([]);
  const [routingProfileIds, setRoutingProfileIds] = useState<string[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "username",
      sort: "asc",
    },
  ]);

  useEffect(() => {
    if (previousPage !== page) {
      setLoading(true);
    }
  }, [page, previousPage]);

  useEffect(() => {
    if (aggregatedQueues?.length > 0) {
      (async () => {
        const newQueueIds = uniq(
          aggregatedQueues
            .map((aggregatedQueue) => aggregatedQueue.queues ?? [])
            .flat()
        );
        setQueueIds(newQueueIds);
      })();
    }
  }, [aggregatedQueues]);

  useEffect(() => {
    if (queueIds.length > 0) {
      (async () => {
        let nextToken = null,
          routingProfileIds: string[] = [];

        const filter = {
          or: queueIds.map((queue) => ({
            queueId: {
              eq: queue,
            },
          })),
        };

        do {
          const result: AppSyncQueryResult<
            {
              listQueueRoutingProfiles: {
                items: QueueRoutingProfile[];
                nextToken?: string;
              };
            },
            OperationVariables
          > = await listQueueRoutingProfiles({
            variables: {
              filter,
              nextToken,
            },
          });

          if (result.errors) console.error(result.errors);

          nextToken = result.data?.listQueueRoutingProfiles.nextToken;
          routingProfileIds = [
            ...routingProfileIds,
            ...(result.data?.listQueueRoutingProfiles.items.map(
              ({ routingProfileId }) => routingProfileId
            ) ?? []),
          ];
        } while (nextToken);

        setRoutingProfileIds(uniq(routingProfileIds));
      })();
    }
  }, [listQueueRoutingProfiles, queueIds]);

  useEffect(() => {
    if (routingProfileIds.length > 0) {
      (async () => {
        let agents: Agent[] = [],
          nextToken = null;

        const filter = {
          or: routingProfileIds.map((routingProfileId) => ({
            routingProfileId: {
              eq: routingProfileId,
            },
          })),
        };

        do {
          const result: AppSyncQueryResult<
            {
              listAgents: {
                items: Agent[];
                nextToken?: string;
              };
            },
            OperationVariables
          > = await listAgents({
            variables: {
              filter,
              nextToken,
            },
          });

          if (result.errors) console.error(result.errors);

          agents = [...agents, ...(result.data?.listAgents.items || [])];
          nextToken = result.data?.listAgents.nextToken;
        } while (nextToken);

        setAgents(agents);
        setLoading(false);
      })();
    }
  }, [listAgents, routingProfileIds]);

  useEffect(() => {
    let rows: GridRowsProp = [];

    for (const agent of agents) {
      if (agent.contacts?.length > 0) {
        for (const contact of agent.contacts) {
          rows = [
            ...rows,
            {
              ...contact,
              // Composite field is required to avoid "Encountered two children with the same key" error.
              id: agent.id + contact.id,
              username: agent.username,
            },
          ];
        }
      } else {
        rows = [...rows, agent];
      }
    }

    setRows(
      maximumStatusDuration
        ? rows.filter((row) => {
            return (
              differenceInMilliseconds(
                new Date(),
                new Date(row.status?.startTimestamp)
              ) < maximumStatusDuration
            );
          })
        : rows
    );
  }, [agents, maximumStatusDuration]);

  const isAgentHandlingPageQueues = useCallback(
    (agent: Agent) => {
      const inboundQueueIds = agent.routingProfile.inboundQueues.items.map(
        (queue) => queue.queueId
      );

      return inboundQueueIds.some((inboundQueueId) => {
        return queueIds.includes(inboundQueueId);
      });
    },
    [queueIds]
  );

  const createAgent = (agent: Agent) => {
    setAgents((agents) => {
      return [...agents, agent];
    });
  };

  const deleteAgent = (agentId: string) => {
    setAgents((agents) => {
      return agents.filter((agent) => {
        return agent.id !== agentId;
      });
    });
  };

  const upsertAgent = (updatedAgent: Agent) => {
    const agentId = updatedAgent.id;

    setAgents((agents) => {
      const agentExists = agents.some((agent) => {
        return agent.id === agentId;
      });

      if (agentExists) {
        return agents.map((agent) => {
          return agent.id === agentId ? updatedAgent : agent;
        });
      } else {
        return [...agents, updatedAgent];
      }
    });
  };

  useSubscription<{ onCreateAgent: Agent }>(gql(ON_CREATE_AGENT), {
    onSubscriptionData: (options) => {
      if (options.subscriptionData.error)
        console.error(options.subscriptionData.error);
      else if (
        options.subscriptionData.data?.onCreateAgent &&
        isAgentHandlingPageQueues(options.subscriptionData.data?.onCreateAgent)
      ) {
        createAgent(options.subscriptionData.data?.onCreateAgent);
      }
    },
    shouldResubscribe: true,
  });

  useSubscription<{ onDeleteAgent: Agent }>(gql(ON_DELETE_AGENT), {
    onSubscriptionData: (options) => {
      if (options.subscriptionData.error)
        console.error(options.subscriptionData.error);
      else if (
        options.subscriptionData.data?.onDeleteAgent &&
        isAgentHandlingPageQueues(options.subscriptionData.data?.onDeleteAgent)
      ) {
        deleteAgent(options.subscriptionData.data?.onDeleteAgent.id);
      }
    },
    shouldResubscribe: true,
  });

  useSubscription<{ onUpdateAgent: Agent }>(gql(ON_UPDATE_AGENT), {
    onSubscriptionData: (options) => {
      if (options.subscriptionData.error)
        console.error(options.subscriptionData.error);
      else {
        const updatedAgent = options.subscriptionData.data?.onUpdateAgent;
        const updatedAgentId = updatedAgent?.id;

        if (updatedAgent && isAgentHandlingPageQueues(updatedAgent)) {
          upsertAgent(updatedAgent);
        } else if (updatedAgentId) {
          deleteAgent(updatedAgentId);
        }
      }
    },
    shouldResubscribe: true,
  });

  const handleSortModelChange = useCallback(
    (params: GridSortModel) => {
      if (!isEqual(params, sortModel)) setSortModel(params);
    },
    [sortModel]
  );

  // TODO: Implement server-side filtering, pagination and sorting
  return (
    <DataGrid
      autoPageSize
      columns={[
        {
          field: "username",
          headerName: "Username",
          width: 150,
        },
        {
          field: "status.type",
          flex: 1,
          headerName: "Status",
          renderCell: ({ row }) => {
            return (
              <>
                {row.status ? (
                  <>
                    <StatusTypeIcon
                      channel={row.channel}
                      initiationMethod={row.initiationMethod}
                      statusType={row.status?.type}
                    />
                    {getStatusTypeValue(row.status?.name, row.status?.type)}
                  </>
                ) : null}
              </>
            );
          },
          sortComparator: (valueA, valueB, paramA, paramB) => {
            const rowA = paramA.api.getRow(paramA.id);
            const rowB = paramB.api.getRow(paramB.id);

            const a = getStatusTypeValue(rowA?.status.name, rowA?.status.type),
              b = getStatusTypeValue(rowB?.status.name, rowB?.status.type);

            if (a < b) return -1;
            else if (a > b) return 1;
            else return 0;
          },
          valueGetter: ({ row }) => {
            return getStatusTypeValue(row.status?.name, row.status?.type);
          },
        },
        {
          field: "status.startTimestamp",
          flex: 1,
          headerName: "Duration",
          renderCell: ({ row }) => {
            if (!row.status) {
              return;
            }

            const durationSeconds = differenceInSeconds(
              new Date(),
              new Date(row.status.startTimestamp)
            );

            // The time on the user's machine may be behind the time on the server.
            return durationSeconds < 0 ? "0s" : formatDuration(durationSeconds);
          },
          valueGetter: ({ row }) => {
            return row.status?.startTimestamp;
          },
        },
      ]}
      // components={{
      //   ColumnMenu,
      // }}
      loading={loading}
      onSortModelChange={handleSortModelChange}
      rowHeight={26}
      rows={rows}
      sortingOrder={["asc", "desc"]}
      sortModel={sortModel}
      sx={{
        backgroundColor: "common.white",
        minWidth: "450px",
      }}
    />
  );
}
