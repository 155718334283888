import { CssBaseline } from "@mui/material";
import React from "react";
import { RequiresAuthorisation } from "./components/auth";
import {
  AmplifyProvider,
  AuthProvider,
  AWSAppSyncClientProvider,
  ThemeProvider,
} from "./contexts";
import { View } from "./view";

function App() {
  return (
    <>
      <AmplifyProvider>
        <AuthProvider>
          <RequiresAuthorisation groups={["Wallboard"]}>
            <AWSAppSyncClientProvider>
              <ThemeProvider>
                <CssBaseline />
                <View />
              </ThemeProvider>
            </AWSAppSyncClientProvider>
          </RequiresAuthorisation>
        </AuthProvider>
      </AmplifyProvider>
    </>
  );
}

export default App;
