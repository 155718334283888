/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent(
    $input: DeleteAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    deleteAgent(input: $input, condition: $condition) {
      id
      contacts {
        id
        agentId
        channel
        initiationMethod
        status {
          name
          startTimestamp
          type
        }
      }
      firstName
      lastName
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      status {
        name
        startTimestamp
        type
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const deleteAggregatedQueue = /* GraphQL */ `
  mutation DeleteAggregatedQueue(
    $input: DeleteAggregatedQueueInput!
    $condition: ModelAggregatedQueueConditionInput
  ) {
    deleteAggregatedQueue(input: $input, condition: $condition) {
      id
      currentMetricData {
        agentsAfterContactWork
        agentsAvailable
        agentsError
        agentsNonProductive
        agentsOnCall
        agentsOnContact
        agentsOnline
        agentsStaffed
        contactsInQueue
        contactsScheduled
        oldestContactAge
        slotsActive
        slotsAvailable
      }
      metricData {
        abandonTime
        afterContactWorkTime
        apiContactsHandled
        callbackContactsHandled
        contactsAbandoned
        contactsAgentHungUpFirst
        contactsConsulted
        contactsHandled
        contactsHandledIncoming
        contactsHandledOutbound
        contactsHoldAbandons
        contactsMissed
        contactsQueued
        contactsTransferredIn
        contactsTransferredInFromQueue
        contactsTransferredOut
        contactsTransferredOutFromQueue
        handleTime
        holdTime
        interactionAndHoldTime
        interactionTime
        occupancy
        queueAnswerTime
        queuedTime
        serviceLevel15
        serviceLevel20
        serviceLevel25
        serviceLevel30
        serviceLevel45
        serviceLevel60
        serviceLevel90
        serviceLevel120
        serviceLevel180
        serviceLevel240
        serviceLevel300
        serviceLevel600
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      id
      name
      pages
      createdAt
      updatedAt
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      id
      name
      pages
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      id
      name
      pages
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoutingProfile = /* GraphQL */ `
  mutation DeleteRoutingProfile(
    $input: DeleteRoutingProfileInput!
    $condition: ModelRoutingProfileConditionInput
  ) {
    deleteRoutingProfile(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      inboundQueues {
        items {
          id
          routingProfileId
          queueId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      config {
        id
        name
        pages
        createdAt
        updatedAt
      }
      configId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      config {
        id
        name
        pages
        createdAt
        updatedAt
      }
      configId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      config {
        id
        name
        pages
        createdAt
        updatedAt
      }
      configId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      id
      contacts {
        id
        agentId
        channel
        initiationMethod
        status {
          name
          startTimestamp
          type
        }
      }
      firstName
      lastName
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      status {
        name
        startTimestamp
        type
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      id
      contacts {
        id
        agentId
        channel
        initiationMethod
        status {
          name
          startTimestamp
          type
        }
      }
      firstName
      lastName
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      status {
        name
        startTimestamp
        type
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const createAggregatedQueue = /* GraphQL */ `
  mutation CreateAggregatedQueue(
    $input: CreateAggregatedQueueInput!
    $condition: ModelAggregatedQueueConditionInput
  ) {
    createAggregatedQueue(input: $input, condition: $condition) {
      id
      currentMetricData {
        agentsAfterContactWork
        agentsAvailable
        agentsError
        agentsNonProductive
        agentsOnCall
        agentsOnContact
        agentsOnline
        agentsStaffed
        contactsInQueue
        contactsScheduled
        oldestContactAge
        slotsActive
        slotsAvailable
      }
      metricData {
        abandonTime
        afterContactWorkTime
        apiContactsHandled
        callbackContactsHandled
        contactsAbandoned
        contactsAgentHungUpFirst
        contactsConsulted
        contactsHandled
        contactsHandledIncoming
        contactsHandledOutbound
        contactsHoldAbandons
        contactsMissed
        contactsQueued
        contactsTransferredIn
        contactsTransferredInFromQueue
        contactsTransferredOut
        contactsTransferredOutFromQueue
        handleTime
        holdTime
        interactionAndHoldTime
        interactionTime
        occupancy
        queueAnswerTime
        queuedTime
        serviceLevel15
        serviceLevel20
        serviceLevel25
        serviceLevel30
        serviceLevel45
        serviceLevel60
        serviceLevel90
        serviceLevel120
        serviceLevel180
        serviceLevel240
        serviceLevel300
        serviceLevel600
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAggregatedQueue = /* GraphQL */ `
  mutation UpdateAggregatedQueue(
    $input: UpdateAggregatedQueueInput!
    $condition: ModelAggregatedQueueConditionInput
  ) {
    updateAggregatedQueue(input: $input, condition: $condition) {
      id
      currentMetricData {
        agentsAfterContactWork
        agentsAvailable
        agentsError
        agentsNonProductive
        agentsOnCall
        agentsOnContact
        agentsOnline
        agentsStaffed
        contactsInQueue
        contactsScheduled
        oldestContactAge
        slotsActive
        slotsAvailable
      }
      metricData {
        abandonTime
        afterContactWorkTime
        apiContactsHandled
        callbackContactsHandled
        contactsAbandoned
        contactsAgentHungUpFirst
        contactsConsulted
        contactsHandled
        contactsHandledIncoming
        contactsHandledOutbound
        contactsHoldAbandons
        contactsMissed
        contactsQueued
        contactsTransferredIn
        contactsTransferredInFromQueue
        contactsTransferredOut
        contactsTransferredOutFromQueue
        handleTime
        holdTime
        interactionAndHoldTime
        interactionTime
        occupancy
        queueAnswerTime
        queuedTime
        serviceLevel15
        serviceLevel20
        serviceLevel25
        serviceLevel30
        serviceLevel45
        serviceLevel60
        serviceLevel90
        serviceLevel120
        serviceLevel180
        serviceLevel240
        serviceLevel300
        serviceLevel600
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQueueRoutingProfile = /* GraphQL */ `
  mutation CreateQueueRoutingProfile(
    $input: CreateQueueRoutingProfileInput!
    $condition: ModelQueueRoutingProfileConditionInput
  ) {
    createQueueRoutingProfile(input: $input, condition: $condition) {
      id
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      queueId
      createdAt
      updatedAt
    }
  }
`;
export const updateQueueRoutingProfile = /* GraphQL */ `
  mutation UpdateQueueRoutingProfile(
    $input: UpdateQueueRoutingProfileInput!
    $condition: ModelQueueRoutingProfileConditionInput
  ) {
    updateQueueRoutingProfile(input: $input, condition: $condition) {
      id
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      queueId
      createdAt
      updatedAt
    }
  }
`;
export const deleteQueueRoutingProfile = /* GraphQL */ `
  mutation DeleteQueueRoutingProfile(
    $input: DeleteQueueRoutingProfileInput!
    $condition: ModelQueueRoutingProfileConditionInput
  ) {
    deleteQueueRoutingProfile(input: $input, condition: $condition) {
      id
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      queueId
      createdAt
      updatedAt
    }
  }
`;
export const createRoutingProfile = /* GraphQL */ `
  mutation CreateRoutingProfile(
    $input: CreateRoutingProfileInput!
    $condition: ModelRoutingProfileConditionInput
  ) {
    createRoutingProfile(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      inboundQueues {
        items {
          id
          routingProfileId
          queueId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRoutingProfile = /* GraphQL */ `
  mutation UpdateRoutingProfile(
    $input: UpdateRoutingProfileInput!
    $condition: ModelRoutingProfileConditionInput
  ) {
    updateRoutingProfile(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      inboundQueues {
        items {
          id
          routingProfileId
          queueId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
