import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import React, { ReactNode } from "react";

const theme = createTheme({
  palette: {
    background: {
      default: "#f6f5f8", // Wash
    },
    primary: {
      main: "#fe418d", // Bright Pink
    },
    secondary: {
      main: "#202124", // Rich Grey
    },
    text: {
      primary: "#333333",
      secondary: "rgba(51, 51, 51, 0.87)",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
  },
});

type ThemeProviderProps = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <MuiThemeProvider theme={responsiveFontSizes(theme)}>
      {children}
    </MuiThemeProvider>
  );
}
