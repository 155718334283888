/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAgent = /* GraphQL */ `
  subscription OnCreateAgent {
    onCreateAgent {
      id
      contacts {
        id
        agentId
        channel
        initiationMethod
        status {
          name
          startTimestamp
          type
        }
      }
      firstName
      lastName
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      status {
        name
        startTimestamp
        type
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAgent = /* GraphQL */ `
  subscription OnUpdateAgent {
    onUpdateAgent {
      id
      contacts {
        id
        agentId
        channel
        initiationMethod
        status {
          name
          startTimestamp
          type
        }
      }
      firstName
      lastName
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      status {
        name
        startTimestamp
        type
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAgent = /* GraphQL */ `
  subscription OnDeleteAgent {
    onDeleteAgent {
      id
      contacts {
        id
        agentId
        channel
        initiationMethod
        status {
          name
          startTimestamp
          type
        }
      }
      firstName
      lastName
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      status {
        name
        startTimestamp
        type
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAggregatedQueue = /* GraphQL */ `
  subscription OnCreateAggregatedQueue {
    onCreateAggregatedQueue {
      id
      currentMetricData {
        agentsAfterContactWork
        agentsAvailable
        agentsError
        agentsNonProductive
        agentsOnCall
        agentsOnContact
        agentsOnline
        agentsStaffed
        contactsInQueue
        contactsScheduled
        oldestContactAge
        slotsActive
        slotsAvailable
      }
      metricData {
        abandonTime
        afterContactWorkTime
        apiContactsHandled
        callbackContactsHandled
        contactsAbandoned
        contactsAgentHungUpFirst
        contactsConsulted
        contactsHandled
        contactsHandledIncoming
        contactsHandledOutbound
        contactsHoldAbandons
        contactsMissed
        contactsQueued
        contactsTransferredIn
        contactsTransferredInFromQueue
        contactsTransferredOut
        contactsTransferredOutFromQueue
        handleTime
        holdTime
        interactionAndHoldTime
        interactionTime
        occupancy
        queueAnswerTime
        queuedTime
        serviceLevel15
        serviceLevel20
        serviceLevel25
        serviceLevel30
        serviceLevel45
        serviceLevel60
        serviceLevel90
        serviceLevel120
        serviceLevel180
        serviceLevel240
        serviceLevel300
        serviceLevel600
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAggregatedQueue = /* GraphQL */ `
  subscription OnUpdateAggregatedQueue {
    onUpdateAggregatedQueue {
      id
      currentMetricData {
        agentsAfterContactWork
        agentsAvailable
        agentsError
        agentsNonProductive
        agentsOnCall
        agentsOnContact
        agentsOnline
        agentsStaffed
        contactsInQueue
        contactsScheduled
        oldestContactAge
        slotsActive
        slotsAvailable
      }
      metricData {
        abandonTime
        afterContactWorkTime
        apiContactsHandled
        callbackContactsHandled
        contactsAbandoned
        contactsAgentHungUpFirst
        contactsConsulted
        contactsHandled
        contactsHandledIncoming
        contactsHandledOutbound
        contactsHoldAbandons
        contactsMissed
        contactsQueued
        contactsTransferredIn
        contactsTransferredInFromQueue
        contactsTransferredOut
        contactsTransferredOutFromQueue
        handleTime
        holdTime
        interactionAndHoldTime
        interactionTime
        occupancy
        queueAnswerTime
        queuedTime
        serviceLevel15
        serviceLevel20
        serviceLevel25
        serviceLevel30
        serviceLevel45
        serviceLevel60
        serviceLevel90
        serviceLevel120
        serviceLevel180
        serviceLevel240
        serviceLevel300
        serviceLevel600
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAggregatedQueue = /* GraphQL */ `
  subscription OnDeleteAggregatedQueue {
    onDeleteAggregatedQueue {
      id
      currentMetricData {
        agentsAfterContactWork
        agentsAvailable
        agentsError
        agentsNonProductive
        agentsOnCall
        agentsOnContact
        agentsOnline
        agentsStaffed
        contactsInQueue
        contactsScheduled
        oldestContactAge
        slotsActive
        slotsAvailable
      }
      metricData {
        abandonTime
        afterContactWorkTime
        apiContactsHandled
        callbackContactsHandled
        contactsAbandoned
        contactsAgentHungUpFirst
        contactsConsulted
        contactsHandled
        contactsHandledIncoming
        contactsHandledOutbound
        contactsHoldAbandons
        contactsMissed
        contactsQueued
        contactsTransferredIn
        contactsTransferredInFromQueue
        contactsTransferredOut
        contactsTransferredOutFromQueue
        handleTime
        holdTime
        interactionAndHoldTime
        interactionTime
        occupancy
        queueAnswerTime
        queuedTime
        serviceLevel15
        serviceLevel20
        serviceLevel25
        serviceLevel30
        serviceLevel45
        serviceLevel60
        serviceLevel90
        serviceLevel120
        serviceLevel180
        serviceLevel240
        serviceLevel300
        serviceLevel600
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConfig = /* GraphQL */ `
  subscription OnCreateConfig {
    onCreateConfig {
      id
      name
      pages
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConfig = /* GraphQL */ `
  subscription OnUpdateConfig {
    onUpdateConfig {
      id
      name
      pages
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConfig = /* GraphQL */ `
  subscription OnDeleteConfig {
    onDeleteConfig {
      id
      name
      pages
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoutingProfile = /* GraphQL */ `
  subscription OnCreateRoutingProfile {
    onCreateRoutingProfile {
      id
      createdAt
      updatedAt
      inboundQueues {
        items {
          id
          routingProfileId
          queueId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRoutingProfile = /* GraphQL */ `
  subscription OnUpdateRoutingProfile {
    onUpdateRoutingProfile {
      id
      createdAt
      updatedAt
      inboundQueues {
        items {
          id
          routingProfileId
          queueId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteRoutingProfile = /* GraphQL */ `
  subscription OnDeleteRoutingProfile {
    onDeleteRoutingProfile {
      id
      createdAt
      updatedAt
      inboundQueues {
        items {
          id
          routingProfileId
          queueId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String!) {
    onCreateUser(owner: $owner) {
      id
      config {
        id
        name
        pages
        createdAt
        updatedAt
      }
      configId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String!) {
    onUpdateUser(owner: $owner) {
      id
      config {
        id
        name
        pages
        createdAt
        updatedAt
      }
      configId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String!) {
    onDeleteUser(owner: $owner) {
      id
      config {
        id
        name
        pages
        createdAt
        updatedAt
      }
      configId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateQueueRoutingProfile = /* GraphQL */ `
  subscription OnCreateQueueRoutingProfile {
    onCreateQueueRoutingProfile {
      id
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      queueId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateQueueRoutingProfile = /* GraphQL */ `
  subscription OnUpdateQueueRoutingProfile {
    onUpdateQueueRoutingProfile {
      id
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      queueId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteQueueRoutingProfile = /* GraphQL */ `
  subscription OnDeleteQueueRoutingProfile {
    onDeleteQueueRoutingProfile {
      id
      routingProfile {
        id
        createdAt
        updatedAt
        inboundQueues {
          items {
            id
            routingProfileId
            queueId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      routingProfileId
      queueId
      createdAt
      updatedAt
    }
  }
`;
