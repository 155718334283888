import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Metric } from ".";
import { AggregatedQueue, Column } from "../../types";

const maxNumberOfColumns = 7;
const maxNumberOfRows = 5;

// Uses the ideal amount of realestate.
function prettierColumns(aggregatedQueue: AggregatedQueue) {
  const metrics = aggregatedQueue.metrics;
  const numberOfMetrics = metrics.length;

  const mod = numberOfMetrics % maxNumberOfRows;
  const quotient = Math.floor(numberOfMetrics / maxNumberOfRows);

  const numberOfColumns = mod === 0 ? quotient : quotient + 1;

  const maxNumberOfRowsInColumnFunc = (columnNumber: number) => {
    if (numberOfColumns >= columnNumber) {
      if (numberOfMetrics % numberOfColumns >= columnNumber) {
        return Math.ceil(numberOfMetrics / numberOfColumns);
      } else {
        return Math.floor(numberOfMetrics / numberOfColumns);
      }
    }
    return 0;
  };

  return metrics.reduce<Column[]>(
    (columns, metric) => {
      const columnNumber = columns.length;

      const maxNumberOfRowsInColumn = maxNumberOfRowsInColumnFunc(columnNumber);

      const endColumn = columns[columns.length - 1];

      const numberOfRowsInColumn = endColumn.length;

      if (numberOfRowsInColumn < maxNumberOfRowsInColumn) {
        endColumn.push(metric);
      } else {
        columns.push([metric]);
      }
      return columns;
    },
    [[]]
  );
}

type MetricsColumnsProps = {
  aggregatedQueue: AggregatedQueue;
};

export default function MetricsColumns({
  aggregatedQueue,
}: MetricsColumnsProps) {
  const [columns, setColumns] = useState<Column[]>([]);

  useEffect(() => {
    setColumns(prettierColumns(aggregatedQueue));
  }, [aggregatedQueue]);

  return (
    <>
      {columns.map((column, index) => {
        return (
          <Grid
            container
            direction="column"
            item
            justifyContent="space-between"
            key={index}
            spacing={1}
            sx={{
              width: `${100 / columns?.length || 0}%`,
            }}
            xs
          >
            {column.map((metric, index) => {
              return (
                <Metric
                  aggregatedQueue={aggregatedQueue}
                  key={index}
                  label={metric.label}
                  name={metric.name}
                  thresholds={metric.thresholds}
                />
              );
            })}
          </Grid>
        );
      })}
    </>
  );
}
