import {
  gql,
  OperationVariables,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { createUser as CREATE_USER } from "../../graphql/mutations";
import {
  getUser as GET_USER,
  listConfigs as LIST_CONFIGS,
} from "../../graphql/queries";
import { AppSyncQueryResult, Config, User } from "../../types";

const Select = styled(MuiSelect)({
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
});

type SelectConfigProps = {
  onChangeConfig: (config: Config) => void;
};

export default function SelectConfig({
  onChangeConfig: handleChangeConfig,
}: SelectConfigProps) {
  const { user } = useAuthenticator();
  const [getUser] = useLazyQuery<{ getUser: User }>(gql(GET_USER));
  const [listConfigs] = useLazyQuery(gql(LIST_CONFIGS));
  const [createUser] = useMutation<{ createUser: User }>(gql(CREATE_USER));
  const [configs, setConfigs] = useState<Config[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<Config>();

  useEffect(() => {
    if (selectedConfig) {
      handleChangeConfig(selectedConfig);
    }
  }, [handleChangeConfig, selectedConfig]);

  useEffect(() => {
    (async () => {
      let configs: Config[] = [],
        nextToken;

      do {
        const result: AppSyncQueryResult<
          { listConfigs: { items: Config[]; nextToken?: string } },
          OperationVariables
        > = await listConfigs();

        if (result.errors) console.error(result.errors);

        configs = [...configs, ...(result.data?.listConfigs.items ?? [])];
        nextToken = result.data?.listConfigs.nextToken;
      } while (nextToken);

      setConfigs(sortBy(configs, "name"));
    })();
  }, [listConfigs]);

  useEffect(() => {
    if (configs.length > 0 && user)
      (async () => {
        const { data } = await getUser({
          variables: {
            id: user.username,
          },
        });

        if (data?.getUser) setSelectedConfig(data.getUser.config);
        else {
          const selectedConfig = configs[0];
          await createUser({
            variables: {
              input: {
                id: user.username,
                configId: selectedConfig.id,
              },
            },
          });
          setSelectedConfig(selectedConfig);
        }
      })();
  }, [configs, createUser, getUser, user]);

  const handleChange = (event: SelectChangeEvent<unknown>) =>
    setSelectedConfig(
      configs.find((config) => config.id === event.target.value)
    );

  return (
    <FormControl>
      <InputLabel id="config-select-label" sx={{ color: "white" }}>
        Config
      </InputLabel>
      <Select
        id="config-select"
        label="Config"
        labelId="config-select-label"
        onChange={handleChange}
        value={selectedConfig?.id || ""}
        sx={{ color: "white" }}
      >
        {configs.map((config) => (
          <MenuItem key={config.id} value={config.id}>
            {config.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
