import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Menu, SelectConfig } from ".";
import { Config } from "../../types";
import icon from "../../assets/Icon pink400px.png";

type AppBarProps = {
  onChangeConfig: (config: Config) => void;
  onNextPage: () => void;
  onPauseResume: () => void;
  onPreviousPage: () => void;
  play: boolean;
};

export default function AppBar({
  onChangeConfig: handleChangeConfig,
  onNextPage: handleNextPage,
  onPauseResume: handlePauseResume,
  onPreviousPage: handlePreviousPage,
  play,
}: AppBarProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <MuiAppBar color="secondary" position="static">
        <Toolbar>
          <img
            alt="Logo"
            src={icon}
            style={{
              cursor: "pointer",
              height: "58px",
              marginRight: theme.spacing(2),
            }}
          />
          <Typography
            sx={{
              flexGrow: 1,
            }}
            variant="h6"
          >
            Wallboard
          </Typography>
          <SelectConfig onChangeConfig={handleChangeConfig} />
          <IconButton
            aria-label="previous"
            onClick={handlePreviousPage}
            sx={{
              color: "primary.contrastText",
            }}
          >
            <SkipPreviousIcon />
          </IconButton>
          <IconButton
            aria-label="previous"
            onClick={handlePauseResume}
            sx={{
              color: "primary.contrastText",
            }}
          >
            {play ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton
            aria-label="previous"
            onClick={handleNextPage}
            sx={{
              color: "primary.contrastText",
            }}
          >
            <SkipNextIcon />
          </IconButton>
          <Menu />
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
}
