import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  Reference,
} from "@apollo/client";
import { Auth } from "aws-amplify";
import { AuthOptions, AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { uniqBy } from "lodash";
import { ReactNode } from "react";

const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const currentSession = await Auth.currentSession();

      return currentSession.getIdToken().getJwtToken();
    },
  },
  region = process.env.REACT_APP_AWS_APPSYNC_REGION ?? "eu-west-2",
  url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT!;

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Agent: {
        fields: {
          contacts: {
            merge(existing: Reference[] | undefined, incoming: Reference[]) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

export default function AWSAppSyncClientProvider({
  children,
}: {
  children: ReactNode;
}) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
