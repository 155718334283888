import { useAuthenticator } from "@aws-amplify/ui-react";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, Menu as MuiMenu, MenuItem } from "@mui/material";
import React, { MouseEvent, useState } from "react";

export default function Menu() {
  const { signOut } = useAuthenticator();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClose = () => setAnchorEl(undefined);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  return (
    <>
      <IconButton
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleOpen}
        sx={{
          color: "primary.contrastText",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <MuiMenu
        anchorEl={anchorEl}
        id="menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </MuiMenu>
    </>
  );
}
